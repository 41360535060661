import * as React from "react";

import Seo from "components/seo";
import Faq from "organisms/support/faq/faq";
import Top from "organisms/support/top";
import Tab from "organisms/support/tab";
import { graphql } from "gatsby";
import { FAQ_CATEGORY } from "../../../constants/data/support";
import { FAQ_META, FAQ_META_EN } from "../../../constants/data/meta_data";
import LayoutSupport from "../../../templates/layoutSupport";
import InquiryBanner from "../../../organisms/banner/inquiryBanner";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const FaqPage = ({ data }) => {
    const { i18n } = useTranslation();

    const faqData = useMemo(() => {
        if (!data[`faq_data${`_${i18n.language}`}`]) return [];

        return data[`faq_data${`_${i18n.language}`}`].edges;
    }, [data, i18n]);

    const seoData = useMemo(() => {
        if (i18n.language === "en") return FAQ_META_EN;

        return FAQ_META;
    }, [i18n.language]);

    return (
        <LayoutSupport>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Top />
            <Tab />
            <Faq data={faqData} category={FAQ_CATEGORY} />
            <InquiryBanner />
        </LayoutSupport>
    );
};

export default FaqPage;

export const FaqData = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        faq_data_ko: allFaqDataKoCsv {
            edges {
                node {
                    id
                    category
                    title
                    desc
                }
            }
        }
        faq_data_en: allFaqDataEnCsv {
            edges {
                node {
                    id
                    category
                    title
                    desc
                }
            }
        }
    }
`;
